var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.branch
    ? _c("GreenContainer", { staticClass: "branchJobTeaserElement" }, [
        _c("div", { staticClass: "branchJobTeaserElement__container" }, [
          _c(
            "div",
            { staticClass: "branchJobTeaserElement__list" },
            [
              _c("span", { staticClass: "branchJobTeaserElement__headline" }, [
                _vm._v(_vm._s(_vm.$t("branch.jobs"))),
              ]),
              _vm._v(" "),
              _c("job-latest", {
                attrs: { context: _vm.context, branch: _vm.branch },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "branchJobTeaserElement__branch" },
            [
              _c("span", { staticClass: "branchJobTeaserElement__headline" }, [
                _vm._v(_vm._s(_vm.$t("branch.myBranch"))),
              ]),
              _vm._v(" "),
              _c("LocationTeaser", {
                attrs: {
                  context: _vm.context,
                  "is-horizontal": _vm.bpMd,
                  branch: _vm.branch,
                },
              }),
              _vm._v(" "),
              _c("JobButtons", {
                staticClass: "branchJobTeaserElement__buttons",
                attrs: { context: _vm.context.pageContext },
                on: { listClick: _vm.onListClick },
              }),
            ],
            1
          ),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }