/*
 * Copyright 2022 LABOR.digital
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Last modified: 2022.02.16 at 23:20
 */

import {Branch} from '../Interface/Branch';
import {JobService} from './JobService';

export class InitiativeApplicationService
{
    public static setSearchParameter(branch: Branch): void
    {
        if (branch) {
            JobService.demand.location = {
                address: branch.city,
                latitude: branch.latitude,
                longitude: branch.longitude
            };
            JobService.state.location.items = [JobService.demand.location];
        }
    }
}
