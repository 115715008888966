




































import {ContentElementContext} from '@labor-digital/typo3-vue-framework/lib/Core/Context/ContentElementContext';
import {AppStoreKeys} from '../../../AppStoreKeys';
import LocationTeaser from '../../../Component/Branch/BranchTeaser/BranchTeaser.vue';
import JobButtons from '../../../Component/Job/JobButtons/JobButtons.vue';
import GreenContainer from '../../../Component/Layout/GreenContainer/GreenContainer.vue';
import {BranchAndGeoLocationService} from '../../../Service/BranchAndGeoLocationService';
import {InitiativeApplicationService} from '../../../Service/InitiativeApplicationService';
import Button from '../Button/Button.vue';
import Headline from '../Headline/Headline.vue';
import JobLatest from '../JobLatest/JobLatest.vue';

export default {
    name: 'BranchJobTeaser',
    components: {JobButtons, Button, Headline, JobLatest, GreenContainer, LocationTeaser},
    props: {
        context: null as ContentElementContext
    },
    computed: {
        branch()
        {
            if (this.context.initialState.has('id')) {
                return this.context.initialState._state;
            }
            return BranchAndGeoLocationService.getCurrentBranch();
        },
        bpMd(): boolean
        {
            return this.context.store.get(AppStoreKeys.BREAKPOINT).is('<=', 'md');
        }
    },
    methods: {
        onListClick()
        {
            InitiativeApplicationService.setSearchParameter(this.branch);
        }
    }
};
